import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { SubmissionError } from 'redux-form';

import Page from '../../components/page';
import ForgottenUsernameForm from '../../components/forgottenusernameform';
import { fetchUserNames } from '../../actions';
import { redirectTo } from '../../actions/utils';
import { viewUserNamesPath, frontpagePath } from '../../routeConstants';

class GetUsernames extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
    }
    cancel() {
        redirectTo(frontpagePath)
    }
    onSubmit(formValues) {
        let payload = Object.assign({}, formValues);
        if (formValues.identifier.length === 11) {
            payload.identifier_type = 'norwegianNationalId';
        } else {
            payload.identifier_type = 'studentNumber';
            // Strip leading zeroes
            payload.identifier = payload.identifier.replace(/^0+/, '')
        }
        return this.props.fetchUserNames(payload)
            .then(
                () => {
                    redirectTo(viewUserNamesPath);
                },
                errorAction => {
                    const _error = {};
                    console.log(errorAction);
                    if (errorAction.error.type === 'not-found-error') {
                        _error._error = <Translate value="requestUsernamesError" />;
                    }
                    else if (errorAction.error.type === 'rate-limit-error') {
                        _error._error = <Translate value="serverErrors.rateLimit" />;
                    }
                    else {
                        _error._error = <Translate value="serverErrors.serverDown" />;
                    }
                    throw new SubmissionError(_error);
                })
    }
    render() {
        return (
            <Page
                header={<Translate value="general.forgotten_username" />}
                infoText={I18n.t('general.get_usernames_info')}
            >
                <ForgottenUsernameForm onSubmit={this.onSubmit} cancel={this.cancel} />
            </Page>
        )
    }
}

GetUsernames.propTypes = {
    fetchUserNames: PropTypes.func.isRequired,
    locale: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        locale: state.i18n.locale,
    }
}

export default connect(mapStateToProps, {
    fetchUserNames
})(GetUsernames
)
