import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { SubmissionError } from 'redux-form';
import spinner from '../../static/spinner.gif'

import Page from '../../components/page';
import pofhConfig from '../../pofhConfig';
import Messages from '../../components/messages';
import { chooseUserPath, frontpagePath, logoutPath} from 'routeConstants';
import { Checkmark } from 'components/icons';
import ChangePinForm from 'components/changepinform';
import { setPinCode, getCardHolder} from 'actions';
import ButtonLink from '../../components/buttonlink';
import Warning from '../../components/warning';
const { pinChangeEnabled } = pofhConfig.appSettings;

class ChangePinPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      cardsHeld: 0,
      checkingCardStatus: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  nextStep = () => {
    const {step} = this.state;
    this.setState({
      step: step+1
    });
  }

  handleSubmit(payload) {
    return this.props.setPinCode({pin_code: payload.pin_code})
      .then(() => {
        this.nextStep();
      },
    errorAction => {
      const _error = {};
      if (errorAction.error.type === 'not-found-error') {
        _error._error = <Translate value="serverErrors.serverDown" />;
      }
      if (errorAction.error.type === 'rate-limit-error') {
        _error._error = <Translate value="serverErrors.rateLimit" />;
      }
      else {
        _error._error = <Translate value="serverErrors.serverDown" />;
      }
      throw new SubmissionError(_error);
    })
  }
  componentDidMount() {
    return (this.props.getCardHolder().then(
      (success) => this.setState({
        checkingCardStatus: false,
        cardsHeld: success.response.kortholder,
        step: 1}),
      this.setState({
        checkingCardStatus: false,
        step: 4,
      })
    ))
  }

  render() {
    const {step} = this.state;
    console.log(this.state.step)
    console.log(this.state.cardsHeld.kortholder)

    switch (step) {
      case 1:
        return pinChangeEnabled && !this.state.checkingCardStatus ? (
          <Page
            header={I18n.t('general.pin_code.header')}
            goBackRoute={chooseUserPath}
          >
            <Messages
              messages={pofhConfig.changePinTexts[this.props.locale]}
            />
            {this.state.cardsHeld >= 2 ?
            (<div>
              <br/>
              <Warning 
              text={I18n.t('general.pin_code.several_cards')}
              color="yellow"
              />
            </div>) : null}
            <ChangePinForm
              onSubmit={this.handleSubmit}
              cancel={this.showCancelPopup}
            />
          </Page>
        ) : (
          <Page goBackRoute={frontpagePath}>
            <img className='spinner' src={spinner} alt='spinner' />
          </Page>
        )
      case 2:
        return (
          <Page
            header={I18n.t('general.pin_code.changed_header')}
            goBackRoute={chooseUserPath}
            image={<div style={{display:'flex', justifyContent:'center'}}>
              <Checkmark/>
            </div>}
          >
            <ButtonLink
            href={logoutPath}
            text={I18n.t('general.logout')}
            wide
            />
          </Page>
        )
      case 3: 
        return (
          <Page
          header={I18n.t('general.pin_code.header')}
          goBackRoute={chooseUserPath}
          warning={
            <Warning
              text={I18n.t('general.pin_code.no_card_holder')}
              color="yellow"
            >
              <p>
                {I18n.t('general.pin_code.go_to')}
                <a href={pofhConfig.changePinUrls[this.props.locale]}>
                  {I18n.t('general.pin_code.card_centre')}
                </a>
              </p>
            </Warning>}
          >
          </Page>
        )
      case 4:
        return (
          <Page goBackRoute={frontpagePath}>
            <img className='spinner' src={spinner} alt='spinner' />
          </Page>
        )
    }
  }
}

ChangePinPage.propTypes = {
  locale: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps, {
  setPinCode,
  getCardHolder,
})(ChangePinPage);
