import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import Button from '../button';
import Warning from '../warning';
import NumberInput from 'components/numberinput';

const validate = (fields) => {
  const error = {};
  if (!fields.pin_code)
    error.pin_code = <Translate value='formErrors.requiredField' />;
	if(fields.pin_code && fields.pin_code.length != 4)
		error.pin_code = <Translate value='formErrors.pinMismatch'/>;
  if(fields.pin_code && isNaN(fields.pin_code))
    error.pin_code = "Bokstaver er ikke tillat";
  if(fields.pin_code && isIdentical(fields.pin_code))
    error.pin_code = "Bare like nummer"
  return error;
};

function isIdentical(input) {
  var numbers = new Set(input);
  if (numbers.size <= 1) {
    return true;
  }
  return false;
}

class ChangePinForm extends React.Component {
  constructor(props) {
    super(props);
    this.toggleShowPinCode = this.toggleShowPinCode.bind(this);
    this.state = {
      showPinCode: false,
    }
  }
  toggleShowPinCode() {
    this.setState({showPinCode: !this.state.showPinCode})
  }
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field name="pin_code"
          locale={this.props.locale}
          type="number"
					inputMode="numeric"
					pattern="[0-9]*"
          label={I18n.t('general.pin_code.pin_code')}
          component={NumberInput}
        />
        {this.props.error && (
          <Warning text={this.props.error} color="red" formWarning={true} />
        )}
        <Button type='submit'
          text={I18n.t('general.pin_code.confirm_change')}
          fetching={this.props.submitting}
          disabled={this.props.pristine || !this.props.valid}
          wide={true}
        />
      </form>
    )
  }
}
ChangePinForm = reduxForm({ form: 'ChangePinForm', validate })(
  ChangePinForm
)

ChangePinForm.propTypes = {
  cancel: PropTypes.func,
  locale: PropTypes.string,
};

export default connect((state) => {
  return {
    locale: state.i18n.locale
  };
})(ChangePinForm);
